.App {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
body {
  overflow: hidden; /* Hide scrollbars */
}
.color-picker-container {
  position: relative;
}
.color-picker-palette {
  position: absolute;
  z-index: 100;
}

.color-picker-cover {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
}

.color-picker-color-background {
  position: fixed;
  height: 35px;
  width: 35px;
  background-color: #999999;
  float: left;
}
.icon-bar button {
  width: auto;
  display: block;
  text-align: center;
  transition: all 0.3s ease;
  font-size: 30px;
}

.icon-vbar {
  width: 100%;
  background-color: #f0f0f0;
  overflow: auto;
}

.icon-vbar div {
  display: inline-block;
  text-align: center;
  padding: 12px;
  transition: all 0.3s ease;
  color: white;
  font-size: 8px;
  width: auto;
}
canvas {
    /* padding: 0; */
    /* margin: auto; */
    /* display: block; */
    /* width: 800px;
    height: 600px; */
    /* position: absolute; */
    /* center: 0; */
    /* top: 0; */
    /* bottom: 0; */
    /* left: 0; */
    /* right: 0; */
    /* outline: black 3px solid; */
}

/* p{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 420px;
  margin: 0;
  font: 18px 'PT Serif', serif;
  transform-origin: 0 0;
  transform: translate(-50%, -50%);
    
  &::first-letter
    float: left;
    margin-right: 9px;
    line-height: 1em;
    font-size: 260%;
    
  &::after
    content: '';
    display: inline-block;
    width: 100%;
} */